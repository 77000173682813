import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import Button from '@interness/web-core/src/components/elements/Button/Button';
import Link from '@interness/web-core/src/components/elements/Link/Link';
import RandomBrandHeaderImages from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <RandomBrandHeaderImages mdxType="RandomBrandHeaderImages" />
    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Unsere Konfiguratoren`}</h1>
      <Button as={Link} fullWidth to='http://www.dertrauringjuwelier.de/egf/creative/konfigurator.php#F-1008-1' mdxType="Button">Trauringjuwelier-Konfigurator</Button>
      <hr></hr>
      <Button as={Link} fullWidth to='http://www.rubin-trauringe.de/ring-designer/catalog/' mdxType="Button">Rubin Konfigurator</Button>
      <hr></hr>
      <p>{`Durch Klick auf die Links verlassen Sie unsere Seite.`}</p>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      